<template>
  <v-container class="fill-height" fluid>
    <v-fade-transition mode="out-in">
      <v-row align="center" justify="center">
        <v-progress-circular size="100" :width="5" color="red" indeterminate></v-progress-circular>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Loading',
  methods: { ...mapActions(['authenticateUserBySSO']) },
  mounted() {
    const access_token = this.$auth.getAccessToken()
    if (access_token !== undefined) {
      this.authenticateUserBySSO({
        params: {},
        headers: {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      })
    } else {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>
